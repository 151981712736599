
/**
 * @name: detail
 * @author: Gzm
 * @date: 2023-07-20 16:19
 * @description：采购管理-采购明细
 * @update: 2023-07-20 16:19
 */
import {Vue, Component} from "vue-property-decorator"
import {IPlatform} from "@/apis/platform/types"
import {ICrudOption} from "@/types/m-ui-crud"
import {deepCopy, exportFile} from "@/utils/common";
import {IPurchaseOrderQuery, IPurchaseOrderQueryDetail} from "@/apis/purchase/types";
import {
  purchaseOrderQueryDetailApi,
  purchaseOrderQueryDetailExportApi,
  purchaseOrderQueryExportApi
} from "@/apis/purchase";

@Component({})
export default class DetailPage extends Vue {
  // 表格加载状态
  tableLoading: boolean = false;
  // 表格数据
  tableData: IPlatform[] = []
  // 表格数据
  tableTotal: number = 0
  // 查询参数
  queryForm: IPurchaseOrderQueryDetail = {
    page: 1,
    limit: 10,
  }
  totalQty: string = '0'
  // 表单数据
  modelForm: Partial<IPlatform> = {}
  // 配置信息
  crudOption: ICrudOption = {
    menu: false,
    column: [
      {
        label: "订单编号",
        prop: "purchaseOrderSn",
        align: "left",
        width: 180,
        editHide: true,
        addHide: true,
        viewHide: true,
        search: true,
      },
      {
        label: "商品名称",
        prop: "goodName",
        addHide: true,
        editHide: true,
        viewHide: true,
        search: true,
        placeholder: "输入商品名称查询"
      },
      {
        label: "商品属性",
        prop: "goodsTypes",
        align: "center",
        type: "switch",
        search: true,
        dicData: [
          {
            label: "单品",
            value: 1
          },
          {
            label: "套餐",
            value: 2
          },
        ]
      },
      {
        label: "采购数量(份)",
        prop: "purchaseQty",
        align: "center",
      },
      {
        label: "发往站点",
        prop: "siteName",
        align: "center",
      },
      {
        label: "采购日期",
        prop: "purchaseTime",
        align: "center",
        search: true,
        type: "daterange",
        searchSlot: true,
      },
      {
        label: "经办人",
        prop: "editName",
        align: "center",
        search: true
      },

    ]
  }

  /**
   * 导出excel
   */
  exportExcel () {
    this.tableLoading = true
    const form: IPurchaseOrderQueryDetail = deepCopy(this.queryForm)
    if (form.purchaseTime && form.purchaseTime.length) {
      form.startTime = form.purchaseTime[0]
      form.endTime = form.purchaseTime[1]
    } else {
      form.startTime = ""
      form.endTime = ""
    }
    delete form.purchaseTime
    purchaseOrderQueryDetailExportApi(form).then(e => {
      exportFile(e, "采购明细.xlsx")
      this.tableLoading = false
    })
  }


  getList() {
    this.tableLoading = true
    const form: IPurchaseOrderQueryDetail = deepCopy(this.queryForm)
    if (form.purchaseTime && form.purchaseTime.length) {
      form.startTime = form.purchaseTime[0]
      form.endTime = form.purchaseTime[1]
    } else {
      form.startTime = ""
      form.endTime = ""
    }
    delete form.purchaseTime
    purchaseOrderQueryDetailApi(form).then(e => {
      console.log(e.purchaseOrderDtlByPageVoPage.list)
      this.tableData = e.purchaseOrderDtlByPageVoPage.list;
      this.tableTotal = e.purchaseOrderDtlByPageVoPage.total;
      this.totalQty = e.totalQty
      this.tableLoading = false
    })
  }


  created() {
    this.getList()
  }
}
